import { AppBar, Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo/logo2.svg";
import CustomButton from "../CustomButton";
import buildIcon from "../../assets/img/vectors/building.svg";
import flash from "../../assets/img/vectors/flash.svg";
import newIcon from '../../assets/img/ticket/apple.svg'

const EventNavbar = () => {
  const [color, setColor] = React.useState(false);

  const changeColor = () => {
    if (window.scrollY >= 60) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  return (
    <>
      <AppBar
        className={" appbar_bg" }
        sx={{ backgroundColor: "#fff", boxShadow: "none" }}
      >
        <Box
          sx={{
            p: 3,
            width: { md: "75%", xs: "90%" },
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <a href="https://www.vibezsup.com/">
            <img src={logo} width={50} />
          </a>
          <Box sx={{display:'flex', alignItems:'center', columnGap:3}}>
            <Link to="/events">
            <Button
            endIcon={<img src={newIcon} width={32} />}
              sx={{
                fontWeight: 700,
                bgcolor: "#FCEDFE",
                 width: {lg:"222px", md:'222px', sm:'auto', xs:'auto'},
                height: {lg:'50px', md:'50px', sm:'40px', xs:'40px'},
                fontSize: { md: "14px", xs: "10px" },
                textTransform:'initial',
                borderRadius:'8px',
                whiteSpace:'nowrap'
              }}
            >
          Get the VibezsUp App
            </Button>
            </Link>
       
          </Box>
        </Box>
      </AppBar>
    </>
  );
};

export default EventNavbar;
