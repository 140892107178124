import { Box, Button, InputLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import successImg from "../../../assets/img/vectors/ticket_buy.svg";
import { checkTicketPayment } from "../../../assets/img/api";
import Loader from "../../../Components/Common/Loader";
import Icons from "../../../constant/Icons";
import failedImg from "../../../Reservation/Assets/img/security _ protection, safety, umbrella, shield, alert, message, antivirus, notification, malware.svg";

const EventPayment = ({ data }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const transaction_reference = urlParams.get("transaction_reference");
  const email = urlParams.get("email");
  const amount = urlParams.get("amount");

  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState({
    success: false,
    failed: false,
    pending: false,
    overpaid: false,
    underpaid: false,
  });

  const { success, failed, pending, overpaid, underpaid } = status;

  const handleCheckPayment = async () => {
    setIsLoading(true);
    setStatus({
    success: false,
    failed: false,
    pending: false,
    overpaid: false,
    underpaid: false,
  });

    await checkTicketPayment(transaction_reference, email)
      .then((res) => {
        const { payment_status, reservation_status } = res?.data?.data;
        console.log(payment_status, reservation_status);
        if (
          payment_status === "pending" ||
          reservation_status === "payment_pending"
        ) {
          setStatus({
            ...status,
            pending: true,
            success: false,
            failed: false,
            overpaid: false,
            underpaid: false,
          });
        }
        if (payment_status === "fully_paid") {
          setStatus({
            ...status,
            pending: false,
            success: true,
            failed: false,
            overpaid: false,
            underpaid: false,
          });
        }
        if (payment_status === "over_paid") {
          setStatus({
            ...status,
            pending: false,
            success: false,
            failed: false,
            overpaid: true,
            underpaid: false,
          });
        }
        if (payment_status === "under_paid") {
          setStatus({
            ...status,
            pending: false,
            success: false,
            failed: false,
            overpaid: false,
            underpaid: true,
          });
        }
        if (payment_status === "failed") {
          setStatus({
            ...status,
            pending: false,
            success: false,

            overpaid: false,
            underpaid: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setStatus({
          ...status,
          pending: false,
          success: false,

          overpaid: false,
          underpaid: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleCheckPayment();
  }, []);

  const handleRepay = () => {
     const pub_key = process.env.REACT_APP_POOLER_KEY;
    window?.Pooler?.Popup({
      amount,
      email,
      pub_key,
    redirect_link: `https://vibezsup.com/event?tab=payment&amount=${amount}&transaction_reference=${transaction_reference}&email=${email}`,
            transaction_reference,
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {success && (
          <>
            <img src={successImg} width={150} />
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "butler",
                fontWeight: 700,
                mt: 2,
              }}
            >
              Ticket Successfully Purchased
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "12px",
                color: "#5b5b5b",
                mt: 1,
              }}
            >
              An email containing your receipt and ticket has been
              <br /> sent to your email.
            </Typography>

            <Box
              sx={{
                mt: 4,
                width: "300px",
                border: "1px solid #dedede",
                borderRadius: "12px",
                bgcolor: "#fff",
                p: 1,
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                columnGap: 2,
              }}
            >
              <Box
                sx={{
                  background: `url(${data?.banner})`,
                  height: "100px",
                  width: "30%",
                  backgroundSize: "cover",
                  borderRadius: "10px",
                }}
              />
              <Box sx={{ width: "70%" }}>
                <InputLabel sx={{ fontWeight: 700, color: "#000" }}>
                  {data?.name}
                </InputLabel>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                    mt: 1,
                  }}
                >
                  <img src={Icons.location2} />
                  <InputLabel sx={{ color: "primary.main", fontSize: "12px" }}>
                    {data?.location}
                  </InputLabel>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                    mt: 1,
                  }}
                >
                  <img src={Icons.calendar} />
                  <InputLabel sx={{ color: "#8f8f8f", fontSize: "12px" }}>
                    {data?.event_day}
                  </InputLabel>
                </Box>
              </Box>
            </Box>
          </>
        )}

        {/* ==========================OVERPAID========================== */}
        {overpaid && (
          <>
            <img src={successImg} width={150} />
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "butler",
                fontWeight: 700,
                mt: 2,
              }}
            >
              Ticket Successfully Purchased
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "12px",
                color: "#5b5b5b",
                mt: 1,
              }}
            >
              An email containing your receipt and ticket has been
              <br /> sent to your email.
            </Typography>
            <Box
              sx={{
                mt: 3,
                border: "0.5px solid  #C56000",
                bgcolor: "#FFF2E5",
                color: "#C56000",
                borderRadius: "12px",
                p: 2,
                boxSizing: "border-box",
                fontSize: "12px",
                textAlign: "center",
                fontWeight: 400,
                width: "300px",
              }}
            >
              We observed an overpayment in the amount received from your
              payment. Please reach out to the host to assist with reversal of
              the excess funds.
            </Box>
          </>
        )}
        {underpaid && (
          <>
            <img src={failedImg} width={150} />
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "butler",
                fontWeight: 700,
                mt: 2,
              }}
            >
              Ticket Purchase Failed
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "12px",
                color: "#5b5b5b",
                mt: 1,
              }}
            >
              The amount received was short of the total reservation fee. Kindly
              reach out to the host to process a total refund or balance the
              shortfall.
            </Typography>

            <Button onClick={handleRepay} sx={{height:'55px', bgcolor:'#000', color:'#fff', mt:4, width:'250px', borderRadius:'12px'}} >Retry Purchase -N{parseFloat(amount).toLocaleString()}</Button>
          </>
        )}
        {pending && (
          <>
            <img src={failedImg} width={150} />
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "butler",
                fontWeight: 700,
                mt: 2,
              }}
            >
         Payment Unconfirmed
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "12px",
                color: "#5b5b5b",
                mt: 1,
              }}
            >
We were unable to confirm your payment, please click button below to reconfirm payment
            </Typography>

            <Button onClick={handleCheckPayment} sx={{height:'55px', bgcolor:'#000', color:'#fff', mt:4, width:'250px', borderRadius:'12px'}} >Reconfirm Payment</Button>
          </>
        )}
  
      </Box>
    </>
  );
};

export default EventPayment;
