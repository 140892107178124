import React, { useState, useEffect } from "react";
import EventNavbar from "../../Components/Common/EventNavbar";
import Footer from "../../Components/Common/Footer";
import { Box, Button, Grid, Typography, Modal, IconButton, TextField, useTheme, useMediaQuery} from "@mui/material";
import HomeBlog from "../../Components/Blog/HomeBlog";
import line from "../../assets/img/vectors/line.svg";
import Icons from "../../constant/Icons";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import EventDetails from "./Components/EventDetails";
import { getRandomEvent, getSingleEvent } from "../../assets/img/api";
import { EventCard } from "./Event";
import EventTickets from "./Components/EventTickets";
import EventPayment from "./Components/EventPayment";
import Loader from "../../Components/Common/Loader";
import { CloseOutlined } from "@mui/icons-material";
import { MdOutlineDone } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { IoIosCopy } from "react-icons/io";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  borderRadius: "10px",
  p: 3,
};

const SingleEvent = () => {
  const [activeStep, setActiveStep] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState(null);
  const [random, setRandom] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const navigate = useNavigate();

  const { id } = useParams();

  const handleGetEvent = async () => {
    setIsLoading(true);




    await getSingleEvent(id)
      .then((res) => {
        setEvent(res?.data?.data?.event);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

        const [copied, setCopied] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

    const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => console.error("Failed to copy:", err));
  };


  const handleGetRandomEvents = async () => {
    setIsLoading(true);

    await getRandomEvent(id).then((res) => {
      setIsLoading(false);
      setRandom(res?.data?.data?.event);
    });
  };
  useEffect(() => {
    handleGetEvent();
    handleGetRandomEvents();
  }, [id]);

  const location = useLocation();

  const addQueryParam = (key, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

    const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: event?.name,
          text: event?.description,
          url: window.location.href,
        });
        // console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content", error);
      }
    } else {
      // handleOpen()
      alert("Web Share API is not supported in your browser.");
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParams = searchParams.get("tab");
    if (tabParams === "details" || !tabParams) {
      setActiveStep(0);
    }
    if (tabParams === "tickets") {
      setActiveStep(1);
    }
    if (tabParams === "payment") {
      setActiveStep(2);
    }
  }, [location]);
  return (
    <>
      {isLoading && <Loader />}
      <EventNavbar />
      <Box
        sx={{
          width: { lg: "75%", md: "75%", xs: "95%" },
          margin: "0 auto",
          mt: {lg:15, md:15, sm:12, xs:12},
        }}
      >
        {activeStep === 0 && (
          <Button
            onClick={() => navigate("/")}
            startIcon={<img src={Icons.back} />}
            sx={{ color: "#000", fontWeight: 700, textTransform: "initial" , fontSize:{md:'14px', xs:'12px'}}}
          >
            Back to all Events
          </Button>
        )}
        {activeStep === 1 && (
          <Button
            onClick={() => {
              setActiveStep(0);
              addQueryParam("tab", "details");
            }}
            startIcon={<img src={Icons.back} />}
            sx={{ color: "#000", fontWeight: 700, textTransform: "initial", fontSize:{md:'14px', xs:'12px'} }}
          >
            Back to event details
          </Button>
        )}

        <Box sx={{ mt: {lg:4,md:4, sm:3, xs:2}, mb: 5 }}>
          <Box
            sx={{
              bgcolor: "#f9f9f9",
              boxSizing: "border-box",
              p: {lg:5,md:5, sm:3, xs:2},
              borderRadius: "20px",
            }}
          >
            {activeStep === 0 && (
              <>
                <EventDetails
                shareAction={isMobile ? handleShare : handleOpen}
                  data={event}
                  handleNext={() => {
                    setActiveStep(1);
                    addQueryParam("tab", "tickets");
                  }}
                />
              </>
            )}
            {activeStep === 1 && (
              <>
                <EventTickets data={event} />
              </>
            )}
            {activeStep === 2 && (
              <>
                <EventPayment data={event} />
              </>
            )}
          </Box>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              Other events you may like
            </Typography>
            <Typography
              sx={{
                color: "primary.main",
                textDecoration: "underline",
                fontWeight: 600,
              }}
            >
              View all events
            </Typography>
          </Box>

          <Box sx={{ mt: 3, mb: 10 }}>
            <Grid container spacing={3}>
              {random?.map((event, index) => (
                <Grid item lg={6} md={6} sm={12} xs={12} key={index}>
                  <Link to={`/event/${event.id}`}>
                    <EventCard data={event} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      <HomeBlog />
      <Box
        sx={{
          width: "70%",
          margin: "0 auto",
          background: `url('${line}')`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          py: { md: 5, xs: 0 },
        }}
      />
      <Footer />


            <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
                Share link
              </Typography>
              <Typography sx={{ fontSize: "12px", mt:2 }}>
                Recommend<b> {event?.name}</b> event to a friend.
              </Typography>
            </Box>
            <IconButton onClick={handleCloseModal}>
              <CloseOutlined />
            </IconButton>
          </Box>

          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            <TextField
              size="small"
              fullWidth
              value={`https://www.events.vibezsup.com/event/${id}`}
            />
            <IconButton
              onClick={() =>
                handleCopy(`https://www.events.vibezsup.com/event/${id}`)
              }
            >
              {copied ? <MdOutlineDone /> : <IoIosCopy />}
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SingleEvent;
