import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EventCard from "../../../Components/Events/EventCard";
import Icons from "../../../constant/Icons";

import plus from "../../../Reservation/Assets/img/plus.svg";
import minus from "../../../Reservation/Assets/img/minus.svg";
import { buyTicket } from "../../../assets/img/api";
import Loader from "../../../Components/Common/Loader";

const EventTickets = ({ data }) => {
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [error, setError] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const increaseQuantity = (ticket) => {
    setSelectedTickets((prevSelectedTickets) => {
      const existingTicket = prevSelectedTickets.find(
        (t) => t.id === ticket.id
      );
      if (existingTicket) {
        // If the ticket already exists, increase its quantity
        return prevSelectedTickets.map((t) =>
          t.id === ticket.id ? { ...t, quantity: t.quantity + 1 } : t
        );
      } else {
        // If the ticket doesn't exist, add it with a quantity of 1
        return [...prevSelectedTickets, { ...ticket, quantity: 1 }];
      }
    });
  };

  // Function to handle decreasing the quantity of a ticket
  const decreaseQuantity = (ticket) => {
    setSelectedTickets((prevSelectedTickets) => {
      const existingTicket = prevSelectedTickets.find(
        (t) => t.id === ticket.id
      );
      if (existingTicket.quantity === 1) {
        // If quantity is 1, remove the ticket from the array
        return prevSelectedTickets.filter((t) => t.id !== ticket.id);
      } else {
        // Otherwise, decrease the quantity
        return prevSelectedTickets.map((t) =>
          t.id === ticket.id ? { ...t, quantity: t.quantity - 1 } : t
        );
      }
    });
  };

  const totalPrice = selectedTickets.reduce(
    (total, ticket) => total + parseFloat(ticket.price) * ticket.quantity,
    0
  );
  const totalQuantity = selectedTickets.reduce(
    (total, ticket) => total + ticket.quantity,
    0
  );

  const [userDetails, setUserDetails] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
  });
  const { fullName, email, phoneNumber } = userDetails;

  const [isLoading, setIsLoading] = useState(false);


  const handleBuyTicket = async () => {
    setIsLoading(true);
    const refinedTicket = selectedTickets.map((ticket) => ({
      id: ticket.id,
      quantity: ticket.quantity,
    }));

    await buyTicket(data?.id, refinedTicket, fullName, email)
      .then((res) => {
        console.log(res);
        if (res?.data?.status) {
          const {
            amount,
            email,
            full_name,
            transaction_reference,
            redirect_link,
          } = res?.data?.data;

           const pub_key = process.env.REACT_APP_POOLER_KEY;
          window?.Pooler?.Popup({
            amount,
            email,
            pub_key,
            redirect_link: `https://events.vibezsup.com/event/${data?.id}?tab=payment&amount=${amount}&transaction_reference=${transaction_reference}&email=${email}`,
            transaction_reference,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      <Box>
        <Grid container spacing={2}>
          <Grid item md={2.8} sm={4} xs={12}>
            <Box>
              <EventCard data={data} />
            </Box>
          </Grid>
          <Grid item md={5} sm={8} xs={12}>
            <Box
              sx={{
                bgcolor: "#fff",
                p: 2,
                border: "1px solid #dedede",
                borderRadius: "12px",
                boxSizing: "border-box",
              }}
            >
              <Typography sx={{ fontSize: "12px", color: "#8f8f8f" }}>
                Which of the ticket(s) will you like to buy?
              </Typography>
              <Box sx={{ mt: 2 }}>
                {data?.ticket?.map((ticket, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                      borderBottom: "0.5px solid #dedede",
                      pb: 1,
                      mb: 2,
                      ":last-child": {
                        border: "none",
                      },
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                        {`${ticket?.name} Ticket `}{" "}
                        <span style={{ fontWeight: 300 }}>
                          - Admit {ticket?.admission_count}
                        </span>
                      </Typography>
                      <Typography sx={{ color: "#8f8f8f", fontSize: "12px" }}>
                        N{parseFloat(ticket?.price).toLocaleString()}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        border: "1px solid",
                        borderColor: "primary.main",
                        borderRadius: "4px",
                        width: "86px",
                        height: "31px",
                        display: "flex",
                        boxSizing: "border-box",
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: 0.5,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          if (
                            selectedTickets.find((t) => t.id === ticket.id)
                              ?.quantity > 0
                          ) {
                            decreaseQuantity(ticket);
                          }
                        }}
                      >
                        <img src={minus} />
                      </IconButton>
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: "10px",
                          fontWeight: 600,
                        }}
                      >
                        {selectedTickets.find((t) => t.id === ticket.id)
                          ?.quantity || 0}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          const ticketInCart = selectedTickets.find(
                            (t) => t.id === ticket.id
                          );
                          const currentQuantity = ticketInCart?.quantity || 0;

                          if (currentQuantity < ticket.ticket_unsold) {
                            increaseQuantity(ticket);
                          } else {
                            alert("No more tickets available for this type.");
                          }
                        }}
                      >
                        <img src={plus} alt="Add" />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                bgcolor: "#fff",
                p: 3,
                border: "1px solid #dedede",
                borderRadius: "12px",
                boxSizing: "border-box",
                mt: 2,
              }}
            >
              <Typography sx={{ fontSize: "12px", color: "#8f8f8f" }}>
                Please provide details for your ticket
              </Typography>
              <Box sx={{ mt: 2 }}>
                <InputLabel sx={{ fontSize: "12px", mb: 0.3 }}>
                  {" "}
                  Full Name
                </InputLabel>
                <TextField
                  value={fullName}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      fullName: e.target.value,
                    })
                  }
                  fullWidth
                  placeholder="James Smith"
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      fontSize: "12px",
                    },
                  }}
                />
                <InputLabel sx={{ fontSize: "12px", mt: 2, mb: 0.3 }}>
                  Email Address
                </InputLabel>
                <TextField
                  fullWidth
                  value={email}
                  error={error}
                  helperText={error ? "Not a valid email" : ""}
                  // onChange={handleInputChange}
                  onChange={(e) => {
                    setUserDetails({
                      ...userDetails,
                      email: e.target.value,
                    });
                    setError(!validateEmail(e.target.value));
                  }}
                  placeholder="email@gmail.com"
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      fontSize: "12px",
                    },
                  }}
                />
                <InputLabel sx={{ fontSize: "12px", mt: 2, mb: 0.3 }}>
                  Phone Number (Optional)
                </InputLabel>
                <TextField
                  fullWidth
                  value={phoneNumber}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      phoneNumber: e.target.value,
                    })
                  }
                  placeholder="080******"
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      fontSize: "12px",
                    },
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={4.2} sm={12} xs={12}>
            <Box
              sx={{
                bgcolor: "#FCEDFE",
                boxSizing: "border-box",
                p: 3,
                borderRadius: "12px",
              }}
            >
              <Typography sx={{ color: "primary.main", fontWeight: 600 }}>
                Checkout
              </Typography>
              <Box sx={{ mt: 2 }}>
                {data?.ticket?.map((tick, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                      }}
                    >
                      <img src={Icons.ticket} width={20} />
                      <Typography sx={{ fontSize: "14px", color: "#8f8f8f" }}>
                        {tick?.name} Ticket -{" "}
                        <b style={{ color: "#000" }}>
                          x
                          {selectedTickets.find((t) => t.id === tick.id)
                            ?.quantity || 0}
                        </b>
                      </Typography>
                    </Box>
                    <Typography sx={{ color: "primary.main", fontWeight: 600 }}>
                      N
                      {parseFloat(
                        tick?.price *
                          (selectedTickets.find((t) => t.id === tick.id)
                            ?.quantity || 0)
                      ).toLocaleString()}
                    </Typography>
                  </Box>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 2,
                    borderBottom: "1px solid",
                    borderColor: "primary.main",
                    pb: 2,
                  }}
                >
                  <Typography sx={{ fontSize: "14px", color: "#8f8f8f" }}>
                    Service Fee
                  </Typography>

                  <Typography sx={{ color: "primary.main", fontWeight: 600 }}>
                    N{totalPrice > 1 ? 500 : 0}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Typography sx={{ fontSize: "14px", color: "#8f8f8f" }}>
                    Total - <b style={{ color: "#000" }}>x{totalQuantity}</b>
                  </Typography>

                  <Typography sx={{ color: "primary.main", fontWeight: 600 }}>
                    N
                    {(totalPrice + (totalPrice > 1 ? 500 : 0)).toLocaleString()}
                  </Typography>
                </Box>
              </Box>

              <Button
                onClick={handleBuyTicket}
                variant="contained"
                fullWidth
                disabled={
                  !fullName || !email || error || totalPrice === 0 || isLoading
                }
                sx={{
                  mt: 5,
                  height: "50px",
                  borderRadius: "8px",
                  textTransform: "initial",
                }}
              >
                {isLoading ? <CircularProgress size={20} /> : "Buy Ticket"}
              </Button>

              <Box sx={{ mt: 4, bgcolor: "#fff", p: 2, borderRadius: "8px" }}>
                <Typography
                  sx={{
                    color: "primary.error",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  Refund Policy
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    fontSize: "10px",
                    lineHeight: "13px",
                    ml: -3,
                    color: "#8f8f8f",
                  }}
                >
                  <ul>
                    <li>
                      For event cancelation, kindly reach out to the event
                      organizer for full refund. The organizer’s details will be
                      contained in the ticket.
                    </li>
                    <li>
                      The service fee of N500 is not refundable regardless of
                      the cancellation time.
                    </li>
                    <li>
                      Please confirm the organizer's no-show refund policy
                      before purchasing your ticket. VibezsUp will not be
                      responsible for any refund expectations
                      related to no-shows
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EventTickets;
